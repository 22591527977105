.hero-image::after {
    display: block;
    position: absolute;
    background-color: rgba(255, 255, 255, 0);
    bottom: 0;
    left: 0;
    right: 0;
    height: 75vh;
    width: 100%;
    content: "";
}

.padding_percent {
    padding-left: 35%;
    padding-right: 35%;
}

::-webkit-input-placeholder {
    text-align: center;
}

b {
    font-family: Helvetica;
}

span {
    font-family: Helvetica;
}

:-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
}

::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
}

:-ms-input-placeholder {
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.form-radio:checked,
.form-radio:not(:checked) {
    position: absolute;
    left: -9999px;
}

.form-radio:checked + label,
.form-radio:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}

.form-radio:checked + label:before,
.form-radio:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 20px;
    height: 20px;
    border: 1px solid #ddd;
    border-radius: 1px;
    background: #fff;
}

.form-radio:checked + label:after,
.form-radio:not(:checked) + label:after {
    content: "";
    width: 20px;
    height: 20px;
    background: #18d1cc;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 1px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.form-radio:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.form-radio:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.footer-text {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.text-gray {
    font-size: 0.7rem !important;
    color: #b7b7b8;
}

.bg-red {
    background-color: rgba(255, 0, 71, 1);
}

.text-red {
    color: #ff0047;
}

.errors.help-block {
    padding: 4px;
    margin-top: 7px;

    color: #b94a48;
    background-color: #f2dede;
    border-color: #eed3d7;
}

.errors.help-block {
    padding-left: 22px;
    font-size: 15px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAtRJREFUeNqkVc1u00AQHq+dOD+0poIQfkIjalW0SEGqRMuRnHos3DjwAH0ArlyQeANOOSMeAA5VjyBxKBQhgSpVUKKQNGloFdw4cWw2jtfMOna6JOUArDTazXi/b3dm55socPqQhFka++aHBsI8GsopRJERNFlY88FCEk9Yiwf8RhgRyaHFQpPHCDmZG5oX2ui2yilkcTT1AcDsbYC1NMAyOi7zTX2Agx7A9luAl88BauiiQ/cJaZQfIpAlngDcvZZMrl8vFPK5+XktrWlx3/ehZ5r9+t6e+WVnp1pxnNIjgBe4/6dAysQc8dsmHwPcW9C0h3fW1hans1ltwJhy0GxK7XZbUlMp5Ww2eyan6+ft/f2FAqXGK4CvQk5HueFz7D6GOZtIrK+srupdx1GRBBqNBtzc2AiMr7nPplRdKhb1q6q6zjFhrklEFOUutoQ50xcX86ZlqaZpQrfbBdu2R6/G19zX6XSgh6RX5ubyHCM8nqSID6ICrGiZjGYYxojEsiw4PDwMSL5VKsC8Yf4VRYFzMzMaxwjlJSlCyAQ9l0CW44PBADzXhe7xMdi9HtTrdYjFYkDQL0cn4Xdq2/EAE+InCnvADTf2eah4Sx9vExQjkqXT6aAERICMewd/UAp/IeYANM2joxt+q5VI+ieq2i0Wg3l6DNzHwTERPgo1ko7XBXj3vdlsT2F+UuhIhYkp7u7CarkcrFOCtR3H5JiwbAIeImjT/YQKKBtGjRFCU5IUgFRe7fF4cCNVIPMYo3VKqxwjyNAXNepuopyqnld602qVsfRpEkkz+GFL1wPj6ySXBpJtWVa5xlhpcyhBNwpZHmtX8AGgfIExo0ZpzkWVTBGiXCSEaHh62/PoR0p/vHaczxXGnj4bSo+G78lELU80h1uogBwWLf5YlsPmgDEd4M236xjm+8nm4IuE/9u+/PH2JXZfbwz4zw1WbO+SQPpXfwG/BBgAhCNZiSb/pOQAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: 3px 7px;
}

.red-border {
    outline: 2px solid red;
}

.errors.hide.help-block {
    display: none;
}

form.mt-10.mb-4 p.text-2xl {
    margin-left: 50px !important;
}

.text-left.flex {
    margin-top: 1.5rem;
}

@media only screen and (max-width: 300px) {
    .col-span-2.mx-8.mr-5 {
        margin-right: 0.5rem !important;
    }

    form .col-sm-10 .text-2xl.font-bold {
        width: 150px;
        font-size: 21px;
        padding-left: 0px;
        margin-left: 0px !important;
    }

    .grid.grid-cols-2.col-span-6 {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .grid.grid-cols-2 .text-left.flex:nth-child(2) {
        margin-left: 20px;
    }
}

.head-res-mob {
    border: 2px solid #125daa;
    background: #125daa;
    padding: 5px 10px !important;
    color: #fff;
    border-radius: 5px;
    font-size: 15px;
}

.mob-btn-set {
    display: none;
}
.need-help-text {
    display: none;
}

.custom-design-svg.svg-hed {
    position: absolute;
    left: -38px;
    font-size: 24px;
    top: 4px;
}

.custom-margin-top {
    margin-top: 1.5rem !important;
}

@media (min-width: 1200px) {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1220px !important;
    }
}

@media (max-width: 768px) {
    .mob-btn-set {
        display: block !important;
        display: flex !important;
        align-items: flex-end;
        position: absolute;
        right: 0;
        top: 0;
        margin-right: 5px !important;
    }
    .rm-btn-num {
        display: none;
    }
}

.nextbutton {
    background: #ff0047;
}
.nextbutton:hover {
    background: #ff0047;
}
.bg-orange, .orange {
          background-color: #ff9700!important;
        }

        .orange:hover {
          background-color: #d99430;
        }
        .text-orange {
          color: #ff9700;
        }
        .radial-progress-container svg circle:nth-child(3) {
          stroke: #ff9700;
        }